// Takes a list of recipients and converts them to a list of sendEmailJob payloads/previews/.
// Used for compiliing a list of email previews to view before sending them and to be used as payloads for the sendEmailJob.

import useShareholdersByIds from "@/hooks/shares/useShareholdersByIds";
import { generateHtmlForRecord } from "../../components/tiptap-editor/editorPreview";

export default (recipients, editor) => {
	const recipientIds = recipients.map((r) => r.id) ?? [];

	const { data: shareholdersByIdsQuery, isLoading: isLoadingShareholders } =
		useShareholdersByIds(recipientIds);
	const shareholdersByIds = shareholdersByIdsQuery?.data;

	const shareholderById =
		shareholdersByIds?.reduce((acc, s) => ({ [s.id]: s, ...acc }), {}) ?? {};

	return {
		isLoading: isLoadingShareholders,
		data: recipients.map((r) => {
			const id = r.id;
			const investor = shareholderById[id];
			const info = investor?.investorInformation;
			const avatarId = investor?.investorId ?? id;
			const nameFull = info?.name;
			const nameFirst = nameFull?.split(" ")?.shift?.();
			const nameLast = nameFull?.split(" ")?.pop?.();
			const emailAddress = r.email;
			const bankInfo = info?.bankInfo;
			const sharesTotal = investor?.details?.numOfTotalShares ?? 0;
			const sharesPercentageTotal =
				typeof investor?.details?.ownershipPercentage === "number"
					? Number(investor.details.ownershipPercentage.toFixed(2))
					: 0;

			const record = {
				nameFull,
				nameFirst,
				nameLast,
				emailAddress,
				bankInfo,
				sharesTotal,
				sharesPercentageTotal,
			};

			const body = generateHtmlForRecord(editor, record);

			return {
				id,
				name: nameFull,
				email: emailAddress,
				avatarId,
				avatarType: investor?.investorTypeOfOwner,
				body,
				record,
			};
		}),
	};
};
